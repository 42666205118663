import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["contactFirstName", "contactLastName"]

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.fields().forEach((field) => {
      if(field) {
        this.adjustWidth(field);

        field.addEventListener("input", this.updateFieldWidth.bind(this));
      }
    })
  }

  fields() {
    const fields = ["contactFirstName", "contactLastName"];

    return fields.map(field => {
      try {
        return this[`${field}Target`];
      } catch {
        return null
      }
    }).filter(field => field)
  }

  updateFieldWidth(e) {
    this.adjustWidth(e.target);

    return e
  }

  adjustWidth(input) {
    const tempSpan = document.createElement("span");
    tempSpan.style.visibility = "hidden";
    tempSpan.style.position = "absolute";
    tempSpan.style.fontSize = getComputedStyle(input).fontSize;
    tempSpan.style.fontFamily = getComputedStyle(input).fontFamily;
    tempSpan.style.whiteSpace = "pre";
    tempSpan.textContent = input.value || input.placeholder;

    document.body.appendChild(tempSpan);
    input.style.width = tempSpan.clientWidth + 30 + "px";
    document.body.removeChild(tempSpan);
  }
}
