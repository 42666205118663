import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nextBtn"];

  connect() {
    console.log("Hello, Stimulus!", this.element);

    this.nextBtnTarget.addEventListener("click", () => this.handleNext());
  }

  handleNext() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.updateZindex()
    }, 100);
  }

  updateZindex() {
    const slides = this.element.querySelectorAll("#slide");

    if(!slides) return;

    slides.forEach((slide) => {
      const classes = slide.className.split(" ").filter((c) => c.startsWith("z-"));

      if (classes.length > 1) {
        slide.classList.remove(classes[0]);
      }
    });
  }
}
